
@font-face {
  font-family: 'Chilanka';
  src: url('../fonts/Chilanka-Regular.woff') format('woff'), /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
   url('../fonts/Chilanka-Regular.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}


@font-face {
  font-family: 'Monsterrat';
  src: url('../fonts/Montserrat-SemiBold.woff') format('woff'), /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
   url('../fonts/Montserrat-SemiBold.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
} 

html {
  font-size: 62.5%;
}

body {
  line-height: 1.5;
  font-size: 1.6rem;
  overflow-x: hidden;
  overflow-y: scroll;
}

.content {
  background-color: rgb(249, 249, 249);
  padding-top: 5rem;
  padding-bottom: 5rem;
  margin-top: 5rem;
  margin-bottom: 5rem;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}

.footer__text {
  margin-right: 1rem;
}

.footer__text__highlight {
  color: #1bcacd;
  font-weight: bold;
}

.footer__iframe {
  font-size: 0;
  height: 30px;
}

.topcontainer{
  margin: auto;
  padding-left:10%;
  padding-right:10%;
  max-width:700px;
  margin-top: 4.5%;
}

.regcontainer{
  margin: auto;
  padding-left:10%;
  padding-right:10%;
  max-width:700px;
}