.header {
  display: flex;
  font-size: 72.5%;
  font-weight: bold;
  text-align: left;
  font-family:'Monsterrat';
  font-size: 47px;
  color: #000000;
  border: 60px;
  -webkit-text-stroke-width: 1.5px;
  -webkit-text-stroke-color: rgb(20, 22, 37);
  padding-left: 5px;
  margin: -5px;
  margin-right: -10px;
  margin-bottom:0px;
  padding-bottom: 0px;
  color: #0057b3;
  font-style: italic;
}

.header:hover{
  color: #0f7e86;
  text-shadow: 0 0 10px #c2f3ff;
}

.title{
  display: flex;
  position: relative;
  font-family: 'Chilanka';
  font-size: 100%;
  max-width: 1600px;
  padding-left: 21.5px;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

.headergroup{
  display: flex;
  position: relative;
  font-family: 'Chilanka';
  font-size: 100%;
  padding-left: 2.5px;
  max-width: 1600px;
  margin:0px;
  display: flex;
  justify-content: space-between;
}

.tagline{
  display: flex;
  padding-left: 5px;
  flex-basis: flex-grow;
  align-self: stretch;
}

.updateline{
  display: flex;
  flex-basis: flex-grow;
  align-self: stretch;
  font-family: 'Times';
  font-style: italic;
  margin-top:20px;
  color: rgb(4, 41, 25);
  font-style: bold;
  font-size: 75%;
}