.footer {
  display: flex;
  justify-content: center;
  padding: 20px 20px 40px 20px;
}

.footnote {
  font-family: 'Times New Roman', Times, serif;
  font-size: 75%;
  position: relative;
  max-width: 1600px;
  padding-right:15px;
  padding-bottom:15px;
  padding-left: 5px;
  margin: auto;
}

.disclaimer {
  font-family: 'Times New Roman', Times, serif;
  font-size: 50%;
  position: relative;
  max-width: 1600px;
  padding-right:15px;
  padding-left: 5px;
  margin: auto;
}