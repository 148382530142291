.embla {
  display: flex;
  position: relative;
  padding-right: 20px;
  padding-left:5px;
  max-width: 1600px;
  margin: auto;
  font-family: 'Times';
  margin-bottom: 0px;
  margin-top: 0px;
  padding-top: 0px;
}

.twitterlink{
  display: flex;
  position: relative;
  font-family: 'Chilanka';
  font-size: 80%;
  max-width: 1600px;
  padding-right:15px;
  padding-bottom:15px;
  padding-left: 7px;
  margin: auto;
}

.pagedots{
  display: flex;
  position: relative;
  font-family: 'Chilanka';
  font-size: 80%;
  max-width: 1600px;
  padding-right:15px;
  padding-bottom:15px;
  padding-left: 5px;
  margin: auto;
}

.embla__viewport {
  overflow: hidden;
}

.embla__viewport.is-draggable {
  cursor: move;
  cursor: grab;
}

.embla__viewport.is-dragging {
  cursor: grabbing;
}

.embla__container {
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
  margin-left: -10px;
}

.embla__slide {
  position: relative;
  min-width: 100%;
  padding-left: 10px;
  padding-bottom: 0px;
}

.embla__dots {
  display: flex;
  list-style: none;
  justify-content: left;
  padding-top: 0px;
}

.embla__dot {
  background-color: transparent;
  cursor: pointer;
  position: relative;
  padding: 0;
  outline: 0;
  border: 0;
  width: 5px;
  height: 5px;
  margin-right: 2.5px;
  margin-left: 2.5px;
  display: flex;
  align-items: center;
}

.embla__dot:after {
  background-color: #efefef;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  content: "";
}

.embla__dot.is-selected:after {
  background-color: #1bcacd;
  opacity: 1;
}